<template>
    <div class="main-wrapper">
        <div class="form">
            <div class="title-section">
                <h3 class="title">{{ blok.title }}</h3>
            </div>

            <form class="resend-form" @submit.prevent="submitForm">
                <FormSelect
                    id="inquiry_type"
                    v-model="formData.category"
                    :blok="{ component: 'FormSelect' }"
                    :label="blok.BookingType ? blok.BookingType : $t('bookingType')"
                    name="inquiry_type"
                    :options="categoryOptions"
                ></FormSelect>
                <FormInput
                    v-model="formData.email"
                    :blok="{ component: 'FormInput' }"
                    :label="blok.Email ? blok.Email : $t('labels.email2')"
                    :placeholder="blok.Email ? blok.Email : $t('placeholders.email2')"
                    name="email"
                    type="email"
                    :error-message="errorMessages.email"
                    @blur="v$.email.$touch"
                ></FormInput>
                <FormInput
                    v-model="formData.reference_id"
                    :blok="{ component: 'FormInput' }"
                    :label="blok.BookingRef ? blok.BookingRef : $t('labels.bookingRef')"
                    :placeholder="blok.BookingRef ? blok.BookingRef : $t('labels.bookingRef')"
                    name="bookingRef"
                    type="text"
                ></FormInput>
                <button type="submit" class="submit-button">{{ $t('resendConfirmation') }}</button>
                <p class="footnote">{{ blok.Footnote }}</p>
            </form>
        </div>
        <transition name="quick-fade">
            <div v-if="loading" class="vld-overlay is-active is-full-page" style="" aria-busy="true">
                <div class="vld-background"></div>
                <div class="vld-icon">
                    <img src="~/assets/images/loading.svg" alt="Loading" />
                </div>
            </div>
        </transition>
        <Transition
            name="custom-classes"
            enter-active-class="animate__animated animate__animated
animate__bounceInRight"
            leave-active-class="animate__animated animate__bounceOutRight"
        >
            <div v-if="isSubmitted" class="noty_layout" :class="{ success }" @click="clearTimeOut">
                <div class="noty_bar">
                    <div class="noty_body">{{ message }}</div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { helpers, required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import type { IForm } from '~ui/types/components/Form';

const props = defineProps<{ blok: IForm }>();
const { t } = useI18n();

interface IFormData {
    email: string;
    category: string;
    reference_id: string;
}

const categoryOptions = [
    { value: 'parking', label: 'Airport Parking' },
    { value: 'hotels', label: 'Airport Hotels with Parking' },
    { value: 'lounges', label: 'Airport Lounges' },
];

const formData: Ref<IFormData> = ref({
    email: '',
    category: 'parking',
    reference_id: '',
});

const rules = computed(() => {
    return {
        email: {
            required: helpers.withMessage(t('forms.valueRequired2'), required),
            email: helpers.withMessage(t('forms.invalidEmail3'), email),
        },
    };
});

const v$ = useVuelidate(rules, formData);

const errorMessages = computed(() => {
    return {
        email: v$.value.email.$errors.map((e) => e.$message)[0],
    };
});

const message = ref('');
const loading = ref(false);
const isSubmitted = ref(false);
const success = ref(false);
const config = useRuntimeConfig();
let timeoutId = null;

const clearTimeOut = () => {
    if (timeoutId) {
        isSubmitted.value = false;
        clearTimeout(timeoutId);
    }
};
const submitForm = async () => {
    clearTimeOut();
    v$.value.$validate();
    message.value = '';
    success.value = false;
    loading.value = true;

    if (!v$.value.$error) {
        await $fetch(`/api/resendConfirmation`, {
            method: 'POST',
            body: {
                email: formData.value.email,
                product_type: formData.value.category,
                reference_id: formData.value.reference_id,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            onResponse: async ({ response }) => {
                if (response.ok) {
                    success.value = true;
                    message.value = props.blok.successMessage;
                    isSubmitted.value = true;
                }
            },
            onResponseError({ response }) {
                if (response) {
                    if (props.blok.errorMessage) {
                        message.value = props.blok.errorMessage;
                    } else if (response._data.data.error?.message) {
                        message.value = response._data.data.error.message;
                    } else {
                        message.value = response._data.data.errors.product_type[0];
                    }
                    success.value = false;
                    isSubmitted.value = true;
                }
            },
        }).catch((err) => {
            success.value = false;
            isSubmitted.value = true;
            if (!message.value) {
                message.value = props.blok.errorMessage;
            }
            console.error(err);
        });
    }

    if (isSubmitted.value) {
        formData.value.email = '';
        formData.value.reference_id = '';
        formData.value.category = 'parking';
        v$.value.$reset();
    }
    loading.value = false;
    timeoutId = setTimeout(() => (isSubmitted.value = false), 10000);
};
</script>

<style lang="postcss" scoped>
.main-wrapper {
    @apply w-full flex justify-center;

    .form {
        @apply bg-[#efefef] relative rounded-[5px] border-2 border-solid border-[#e2e2e2] md:w-[50%] w-full self-center;
    }

    .title-section {
        @apply text-xl leading-6 font-semibold bg-primary text-white pt-3.5 pb-3 px-6 rounded-[5px_5px_0_0] mb-10;

        .sign-in-link {
            @apply hidden md:block text-primary text-[0.95rem];

            &:hover {
                @apply underline;
            }
        }
    }

    .form-subtitle {
        @apply text-base mb-1.5 font-medium md:hidden;
    }

    :deep(.alert) {
        @apply mb-4;
    }

    :deep(.input-label) {
        @apply inline-block align-top text-lg leading-[22px] font-normal text-[#5e5e5e] m-0;
    }

    .resend-form {
        @apply w-full pt-0 pb-8 px-8;

        .group-input {
            @apply grid gap-x-[30px] lg:grid-cols-2;
        }
    }

    :deep(.form-element) {
        @apply normal-case block border text-[#5e5e5e] bg-white appearance-none w-full h-10 relative text-base m-0 px-[15px] py-0 rounded-md border-solid border-[#cecece];
        outline: none;

        &::placeholder {
            @apply text-[#454545] !important;
        }
    }

    .footnote {
        @apply text-base font-normal leading-[1.5625] text-[#666] text-left box-border mt-0 mb-4;
    }

    .submit-button {
        @apply select-none inline-block align-top whitespace-normal shadow-none text-white font-bold bg-primary text-center transition-all duration-[0.4s] ease-[ease] text-xl capitalize cursor-pointer h-auto leading-[1.2] w-auto mt-6 mb-5 mx-0 pt-[11px] pb-2.5 px-2.5 rounded-md border-[none];
        outline: none !important;
        &:hover {
            @apply bg-[#f6ab2f] text-white;
        }
    }

    :deep(.error-message) {
        @apply text-[#dc3545] font-semibold text-[15px] leading-5 mt-3;
    }

    :deep(.form-input) {
        .form-element:focus {
            @apply border-2 border-black text-inherit;
        }

        .form-element.has-error {
            @apply border-2 border-[#dc3545];
        }
    }
    .vld-overlay {
        @apply items-center justify-center overflow-hidden flex z-[1000000] fixed inset-0;
        .vld-background {
            @apply absolute bg-white opacity-50 inset-0;
        }
    }
    .noty_layout {
        @apply fixed z-[9999999] max-w-[90%] w-full mx-auto my-0 p-0 top-[100px] inset-x-0;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
        -webkit-filter: blur(0);
        .noty_bar {
            @apply cursor-pointer overflow-hidden relative bg-[#de636f] text-white mx-0 my-1 p-[5px] rounded-sm border-b-[#ca5a65] border-b border-solid;
            .noty_body {
                @apply font-normal leading-[1.5625] cursor-pointer text-white box-border text-center text-[21px] p-2.5;
                -webkit-font-smoothing: subpixel-antialiased;
            }
        }
        &.success {
            .noty_bar {
                @apply bg-[#afc765] border-b-[#a0b365];
            }
        }
    }
}
</style>
